import React, { useState, useEffect } from "react";
import heartOff from '@img/heart_off.webp';
import heartOn from '@img/heart_on.webp';
import useStoreById from '../hooks/useStoreById';
import { changeStoreFavoriteStatus } from '../api/index';
import { internalTracking } from '../api';
import $ from 'jquery';
import { getPlaceholder, getColor, capitalize } from "../util/anyFunctions";
import { useQuery } from "react-query";

import '@styles/Card.scss';


const Card = ({
    id, name, category, categoryValue, reference, image, storeId, setDataStore, isFavorite, setstoreFavorite,
    setLoading2, setCategoryValue, lat, lng, isfavoritePage, setIsdashPage, img_src, type
}) =>{
    const [clickedStore, setClickedStore] = useState(null);

    const getStoreUseQuery = async ({queryKey}) => {
        if(queryKey[1]){
            setLoading2(true)
            const { store } =  await useStoreById(queryKey[1], categoryValue)
            setLoading2(false)
            return store;
        }else{
            return {}
        }
    }

    const { data, status } = useQuery({
        queryKey: ['storeId', clickedStore],
        queryFn: getStoreUseQuery,
        staleTime: 5 * (60 * 1000),
        cacheTime: 7 * (60 * 1000),
    });

    useEffect(() => {
        if (status === "success" && data && clickedStore) {
            data.lat = lat
            data.lng = lng
            setDataStore(data);
            setClickedStore(null);
        }
    }, [status, data, clickedStore]);


    const handleClick = async() => {
        setLoading2(true)
        setIsdashPage && setIsdashPage(false)
        internalTracking({ page: 'search', lat, lng, storeId, view:'list' })
        setCategoryValue(categoryValue)
        setClickedStore(storeId)
        setLoading2(false)
        $('#response-detail-modal').modal('show');
        $('#response-detail-modal .modal-content').animate({ scrollTop: 0 }, 'fast');
    };

    const color = getColor(categoryValue);
    const placeholder_ = getPlaceholder(categoryValue);

    // const handleClickfavorite = async() => {
    //     setIsdashPage && setIsdashPage(false)
    //     changeStoreFavoriteStatus(({storeId}))
    //     setstoreFavorite({storeId, status: !isFavorite});
    // };

    return (
        <div className="Card" onClick={handleClick}>
            <div className='image'>
                <img src={image || placeholder_} alt="firullife-img" onClick={handleClick}/>
            </div>
            <div className="detail">
                <div className = "detail-text">
                { !isfavoritePage ?
                    <>
                        {/* <p className="name">{category}: {name}</p> */}
                        <p className="name">{name}</p>
                        {type === "físico" ? (
                            <p className="reference">Referencia: {reference && reference.substring(0, 47)+ (reference.length > 47 ? " . . ." : "")}</p>
                        ) : (
                            <p className="reference">Servicio: {capitalize(type)}</p>
                        )}
                    </>
                    :
                    <>
                        <p className="name">{name}</p>
                        <img src={isFavorite ? heartOn: heartOff} alt="firullife-img" onClick={handleClick}/>
                        <p className="reference">Categoría: {category}</p>
                    </>

                }
                </div>
                { !isfavoritePage &&
                    <div className="imgs-container">
                        {img_src.map((img, index) => (
                            <img key={index} src={img} alt="firullife-img" onClick={handleClick}/>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default Card;